import React from "react";
import divider from "../../assets/icons/Divider.svg";
import "./About.scss";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about">
      <div className="about__bigBox" id="about">
        <p className="about__title">
          We’re in the business of making you look good.
        </p>
        <p className="about__text">
          As a provider of innovative logistics and supply-chain services and
          solutions, BET Group Brokers offers a superior customer experience. We
          redefine the standards for time-definite transportation. Our dedicated
          team, with over 10 years of experience in the industry, always provide
          you with tailor-made logistics solutions, customized to your
          particular needs. Think of us as an extension of your company.
        </p>
        <Link to="/services">
          <button className="about__button">Our Services</button>
        </Link>
      </div>
      <img src={divider} alt="divider" className="about__divider" />
    </div>
  );
};

export default About;
