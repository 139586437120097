import React, { useLayoutEffect } from "react";
import Services from "../../components/Services/Services";
import Contact from "../../components/ContactUs/Contact";

const ServicesPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Services />
      <Contact />
    </div>
  );
};

export default ServicesPage;
