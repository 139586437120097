import React from "react";
import "./Footer.scss";
import footer_logo from "../../assets/desktop_footer_bet.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__background">
        <img src={footer_logo} alt="BET Logo" className="footer__logo" />
        <div className="footer__phone">
          <a href="tel:+305-462-0030">
            <span className="footer__info">305-462-0030</span>
          </a>
        </div>
        <div className="footer__email">
          <a href="mailto:info@betgroupbrokers.com">
            <span className="footer__info">info@betgroupbrokers.com</span>
          </a>
        </div>
      </div>
    </div>
  );
}
