import React from "react";
import hero from "../../assets/BET_hero.svg";
import hero_desktop from "../../assets/BET_desktop_logo.svg";
import divider from "../../assets/icons/Divider.svg";
import "./Hero.scss";

export default function Hero() {
  return (
    <div className="hero" id="/">
      <div className="hero__bigBox" id="#about">
        <img
          src={hero}
          alt="BET Group Brokers LLC: 'Bet on us'"
          className="hero__mobile"
        />
        <img
          src={hero_desktop}
          alt="BET Group Brokers LLC: 'Bet on us'"
          className="hero__desktop"
        />
        <a href="#contact">
          <button className="hero__button">CONTACT US</button>
        </a>
        <img src={divider} alt="Divider" className="hero__divider" />
      </div>
    </div>
  );
}
