import React, { useLayoutEffect } from "react";
import MissionStatement from "../../components/MissionStatement/MissionStatement";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Contact from "../../components/ContactUs/Contact";
import "./HomePage.scss";

const HomePage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Hero />
      <About />
      <MissionStatement />
      <Contact />
    </div>
  );
};

export default HomePage;
