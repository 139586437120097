import React from "react";
import "./Services.scss";
import bullet from "../../assets/bullet_point.svg";
import servicesTruck from "../../assets/services_truck.svg";

export default function Services() {
  return (
    <div className="services">
      <div>
        <h1 className="services__h1">Our Services</h1>
        <img
          src={servicesTruck}
          alt="list of services"
          className="services__truck"
        />
        <div className="services__box">
          <div className="services__box">
            <section className="services__list">
              <img
                src={bullet}
                alt="bullet point"
                className="services__bullet--1"
              />
              <img
                src={bullet}
                alt="bullet point"
                className="services__bullet--2"
              />
              <img
                src={bullet}
                alt="bullet point"
                className="services__bullet--3"
              />
              <img
                src={bullet}
                alt="bullet point"
                className="services__bullet--4"
              />
            </section>
            <section>
              <p className="services__text--1">Dry Van Truckload</p>
              <p className="services__text--2">Flatbed / Stepdeck</p>
              <p className="services__text--3">
                Temperature Controlled Fresh & Frozen
              </p>
              <p className="services__text--4">
                Over Dimensional / Other RGN, DD, Conestoga & Power only
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
