import React from "react";
import "./MissionStatement.scss";
const MissionStatement = () => {
  return (
    <div className="mission">
      <div className="mission__bigBox">
        <div>
          <h1 className="mission__h1">Our Mission</h1>
        </div>
        <div className="mission__text">
          <h2 className="mission__h2">
            We are committed to integrity, reliability & productivity
          </h2>
          <h3 className="mission__h3">
            Our mission is to provide a variety of services & tailored logistic
            solutions to exceed the standards of our customers. In order to
            achieve long-term success and profitability, we are committed to
            integrity, reliability & productivity.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MissionStatement;
