import React from "react";
import "./Contact.scss";
import text_icon from "../../assets/icons/text_icon.svg";
import email_icon from "../../assets/icons/email_icon.svg";
import ContactForm from "../ContactForm/ContactForm";

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <div className="contact__bigBox">
        <div className="contact__background">
          <div className="contact__box">
            <h1 className="contact__h1">Get in touch with us</h1>
            <div className="contact__container">
              <img
                src={text_icon}
                alt="text icon"
                className="contact__textIcon"
              />
              <a href="tel:+305-462-0030">
                <span className="contact__info">{`${window.matchMedia('(min-width: 1440px)').matches ? "Call Us: " : ""}305-462-0030`}</span>
              </a>
            </div>
            <div className="contact__container">
              <img
                src={email_icon}
                alt="email icon"
                className="contact__emailIcon"
              />
              <a href="mailto:info@betgroupbrokers.com">
                <span className="contact__info">{`${window.matchMedia('(min-width: 1440px)').matches ? "Email: " : ""}info@betgroupbrokers.com`}</span>
              </a>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}
