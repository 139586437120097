import Hamburger from "./Hamburger";
import { useState } from "react";
import "./Header.scss";
import logo from "../../assets/icons/Group 1.png";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Nav() {
  const handleClickHome = () => {
    if (window.document.location.pathname === "/") {
      window.scrollTo(0, 0);
    }
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  return (
    <div>
      <div className="navigation">
        <div className="navigation__bigbox">
          <Link to="/" onClick={handleClickHome} className="header-logo">
            <img src={logo} alt="BET Group Brokers Logo" />
          </Link>

          <ul>
            <HashLink
              to="/#top"
              smooth
              className="nav-link"
              onClick={toggleHamburger}
            >
              Home
            </HashLink>
            {/* <Link to="/" className="nav-link" onClick={handleClickAbout}>
              About
            </Link> */}
            <HashLink
              smooth
              to="/#about"
              className="nav-link"
              onClick={toggleHamburger}
            >
              About
            </HashLink>

            <Link to="/services" className="nav-link" onClick={toggleHamburger}>
              Services
            </Link>
            <a href="#contact" className="nav-link" onClick={toggleHamburger}>
              Contact
            </a>
          </ul>
          <div className="header-logo-box">
            <Link
              onClick={handleClickHome}
              to="/#top"
              className="header-logo-1"
            >
              <img src={logo} alt="BET Group Brokers Logo" />
            </Link>

            <div className="hamburger" onClick={toggleHamburger}>
              <Hamburger isOpen={hamburgerOpen} />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .navigation ul {
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;

          width: 100vw;
          padding: 0px;
          overflow: hidden;
          z-index: 10;
          left: 0px;
        }
        .navigation ul Link {
          list-style-type: none;
          padding-right: 10px;
        }
        .hamburger {
          display: none;
          z-index: 6;
        }
        @media (max-width: 1124px) {
          .hamburger {
            display: initial !important;
            padding-top: 10px;
            margin-left: 10px;
            z-index: 6;
          }

          .navigation ul {
            display: ${hamburgerOpen ? "flex" : "none"};
            background-color: #ffffff;
            height: 100vh;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            margin-top: 80px;
            margin-right: 200px;
            position: fixed;
          }
        }
      `}</style>
    </div>
  );
}
